// Z-Indexes
$slide-over-z-index: 2200000000
$modal-z-index: 2300000000
$notification-z-index: 2400000000
$popover-index: 2500000000

// Color
$primary-color: #7d34f4
$logoFilterColor: invert(55%) sepia(72%) saturate(2706%) hue-rotate(236deg) brightness(89%) contrast(102%)
$logoFilterWhite: invert(0%)
