@import variables.module

.logoPrimary
  filter: $logoFilterColor

.link
  @apply text-indigo-500
  &:hover
    @apply text-indigo-600
    @apply underline

.checkControl
  @apply focus:ring-indigo-500 focus:border-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded

  &:disabled
    @apply bg-gray-300

  \:global(.dark) &
    @apply border-none bg-slate-600
    &:checked
      @apply bg-indigo-500

    &:disabled
      @apply bg-slate-700

.radioControl
  @apply focus:ring-indigo-500 focus:border-indigo-500 h-4 w-4 text-indigo-600 border-gray-300

  &:disabled
    @apply bg-gray-300

  \:global(.dark) &
    @apply border-none bg-slate-600
    &:checked
      @apply bg-indigo-500

    &:disabled
      @apply bg-slate-700

.textBoxControl
  @apply block w-full shadow-sm sm:text-sm border-gray-300 rounded-md

  &:focus
    @apply ring-indigo-500 border-indigo-500

  &:disabled
    @apply bg-gray-300 text-gray-800

  \:global(.dark) &
    @apply bg-slate-700 text-slate-100 border-none
    &:disabled
      @apply text-slate-400 bg-slate-700

    &::placeholder
      @apply text-slate-400

.textBoxErrorControl
  @apply block w-full shadow-sm sm:text-sm border-red-500 rounded-md
  &:focus
    @apply ring-red-500 border-red-500

  &:disabled
    @apply bg-gray-300

  \:global(.dark) &
    @apply block w-full shadow-sm sm:text-sm bg-slate-700 text-slate-100 border-red-600
    &:focus
      @apply ring-red-600 border-red-600

    &:disabled
      @apply bg-gray-300

    &::placeholder
      @apply text-slate-400