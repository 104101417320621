.loader
  border-top-color: #7d3aed
  -webkit-animation: spinner 1.5s linear infinite
  animation: spinner 1.5s linear infinite

@-webkit-keyframes spinner
  0%
    -webkit-transform: rotate(0deg)
  100%
    -webkit-transform: rotate(360deg)

@keyframes spinner
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.disabled
  @apply bg-opacity-75 pointer-events-none
